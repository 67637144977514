<template>
    <div class="vue-passengers">
        <p>{{index+1}} - пассажир</p>
        <div class="table_header">
            <div>
                <p>Поезд</p>
                <p>
                    № {{item.number}}
                </p>
            </div>
            <div>
                <p>Тип</p>
                <p>
                    {{
                        item.type=='reserved' ? 'Плацкарт' : 
                        item.type=='coupe' ? 'Купе' : 
                        item.type=='sedentary' ? 'Сидячий' : 
                        item.type=='luxury' ? 'Люкс' : 'Гранд'
                    }}
                </p>
            </div>
            <div>
                <p>Место</p>
                <p>{{item.name}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        index: Number
    }
}
</script>

<style lang="scss" scoped>
.vue-passengers {
    border-bottom: 1px solid #E9E9E9;
    padding: 20px 0px;
}
.table_header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    div {
        p {
            margin-bottom: 0;
            &:first-of-type {
                font-size: 12px;
                line-height: 14px;
                color: #FF8413;
            }
            &:last-of-type {
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin-top: 5px;
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>