<template>
  <div>
    <vue-breadcrumbs :breadCrumbs="breadCrumbs" />
    <div class="vue_card">
      <booking-form @submitHandler="submitHandler" :limit="limit.length" />
      <div v-if="ready" class="vue_card__info">
        <div class="locations">
          <p>{{ cardDetails.from_location }} - {{ cardDetails.to_location }}</p>
        </div>
        <passengers
          v-for="(item, index) in passengers"
          :key="index"
          :item="item"
          :index="index"
        />
        <div class="from_to">
          <div class="from_to__box">
            <p class="from_to__box__title">Отправление</p>
            <div class="from_to__box__date">
              <v-icon color="primary">mdi-calendar</v-icon>
              <div>
                <p>{{ cardDetails.from_location }}</p>
                <p>
                  {{
                    $moment(cardDetails.from_date * 1000).format(
                      "DD - MMM, YYYY"
                    )
                  }}
                </p>
                <p>
                  {{ $moment(cardDetails.from_date * 1000).format("HH:mm") }}
                </p>
              </div>
            </div>
          </div>
          <div class="from_to__box">
            <p class="from_to__box__title">Прибытие</p>
            <div class="from_to__box__date">
              <v-icon color="primary">mdi-calendar</v-icon>
              <div>
                <p>{{ cardDetails.to_location }}</p>
                <p>
                  {{
                    $moment(cardDetails.to_date * 1000).format("DD - MMM, YYYY")
                  }}
                </p>
                <p>{{ $moment(cardDetails.to_date * 1000).format("HH:mm") }}</p>
              </div>
            </div>
          </div>
        </div>
        <booking-card-people :people="people" class="my-3" />
        <div class="total_sum">
          <p>Итого</p>
          <p>
            {{
              Number(people.adult_price) * Number($route.query.adult) +
              Number(people.child_price) * Number($route.query.child) +
              Number(people.pensioner_price) * Number($route.query.pensioner)
            }}
            KZT
          </p>
        </div>
      </div>
    </div>
    <vue-booking-modal
      :dialog="dialog"
      :exept="'Экскурсии'"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import VueBreadcrumbs from "@/components/ui/vueBreadcrumbs.vue";
import VueAvatar from "@/components/ui/vueAvatar.vue";
import BookingCardPeople from "@/components/booking/bookingCardPeople.vue";
import BookingForm from "@/components/booking/bookingForm.vue";
import VueBookingModal from "@/components/ui/vueBookingModal.vue";
import { trainService } from "@/services/trains.service.js";
import { setModel } from "@/utils/formatMask.js";
import Passengers from "@/components/trains/passengers.vue";
export default {
  components: {
    VueBreadcrumbs,
    VueAvatar,
    BookingCardPeople,
    BookingForm,
    VueBookingModal,
    Passengers,
  },
  data: () => ({
    ready: false,
    dialog: false,
    cardDetails: {},
    peopleCount: [],
    passengers: [],
    people: {},
    station: {},
  }),
  computed: {
    breadCrumbs() {
      let seatQuery = {
        adult: this.$route.query.adult,
        child: this.$route.query.child,
        pensioner: this.$route.query.pensioner,
        from_date: this.$route.query.from_date,
        from_location: this.$route.query.from_location,
        to_location: this.$route.query.to_location,
      };
      return [
        {
          text: "Бронирование автобусов",
          to: "/booking/trains/",
          query: seatQuery,
        },
        {
          text: "Выбор места",
          to: `/booking/trains/ow/${this.$route.params.id}/`,
          query: {
            ...seatQuery,
            route_station: this.$route.query.route_station,
          },
        },
        {
          text: "Бронирование",
          to: `/booking/trains/ow/${this.$route.params.id}/card/`,
        },
      ];
    },
    limit() {
      const { adult, child, pensioner } = this.$route.query;
      const arr = [];
      for (let i = 0; i < adult; i++) {
        arr.push("adult");
      }
      for (let i = 0; i < child; i++) {
        arr.push("child");
      }
      for (let i = 0; i < pensioner; i++) {
        arr.push("pensioner");
      }
      return arr;
    },
  },
  created() {
    this.getDetails();
  },
  methods: {
    // setStation() {
    //     this.station = this.cardDetails.route_stations.find(el => el.station.id == this.$route.query.to_location)
    // },
    setPeople() {
      let railways = this.$route.query.railways.split(",");
      let adult_price = 0;
      let child_price = 0;
      let pensioner_price = 0;
      for (let i = 0; i < railways.length; i++) {
        adult_price += Number(
          this.cardDetails.route_railways.find((el) => el.id == railways[i])
            .adult_ticket
        );
        child_price += Number(
          this.cardDetails.route_railways.find((el) => el.id == railways[i])
            .child_ticket
        );
        pensioner_price += Number(
          this.cardDetails.route_railways.find((el) => el.id == railways[i])
            .pensioner_ticket
        );
      }
      this.people.adult_price = adult_price;
      this.people.child_price = child_price;
      this.people.pensioner_price = pensioner_price;
    },
    async setPassengers() {
      let seats = this.$route.query.seats.split(",");
      let railways = this.$route.query.railways.split(",");
      const railwaysArr = [];
      const promises = [];
      railways.forEach((id) => {
        promises.push(trainService.getSingleActiveTrainsPlaces(id));
      });
      const res = await Promise.all(promises);
      res.forEach((el) => {
        railwaysArr.push(...el.results);
      });
      for (let i = 0; i < seats.length; i++) {
        this.passengers.push({
          name: railwaysArr.find((el) => el.id == seats[i]).name,
          number: this.cardDetails.route_railways.find(
            (el) =>
              el.railway.id ==
              railwaysArr.find((el) => el.id == seats[i]).railway
          ).railway.railway_number,
          type: this.cardDetails.route_railways.find(
            (el) =>
              el.railway.id ==
              railwaysArr.find((el) => el.id == seats[i]).railway
          ).railway.railway_type,
        });
      }
    },
    async getDetails() {
      try {
        this.$loading(true);
        const { adult, child, pensioner, route_station } = this.$route.query;
        this.cardDetails = await trainService.getSingleActiveTrains({
          id: this.$route.params.id,
          adult,
          child,
          pensioner,
          route_station,
        });
        await this.setPassengers();
        this.setPeople();
        // this.setStation()
        this.ready = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push("/booking/residence");
    },
    async submitHandler(data) {
      try {
        this.$loading(true);
        const formData = this.$copy(data);
        formData.user.forEach((element) => {
          setModel(element, "phone");
        });
        const railway_order_clients = [];
        formData.user.forEach((el) => {
          railway_order_clients.push({
            ...el,
            // birth_date: this.$moment(el.birth_date).valueOf() / 1000,
          });
        });
        const diff = this.limit.length - formData.user.length;
        for (let j = 0; j < diff; j++) {
          railway_order_clients.push(this.$copy(railway_order_clients[0]));
        }
        railway_order_clients.forEach((user, index) => {
          user.seat_place = Number(this.$route.query.seats.split(",")[index]);
        });
        this.limit.forEach((tariff, index) => {
          railway_order_clients[index].tariff = tariff;
        });
        const postData = {
          seal_channel: formData.seal_channel,
          route_railway: Number(this.$route.query.railways.split(",")[0]),
          is_rt: false,
          route_station: this.$route.query.route_station,
          // station: this.station.station.id,
          railway_order_clients,
        };
        await trainService.postTrainOrder(postData);
        this.dialog = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vue_card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  grid-column-gap: 20px;
  &__info {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(146, 178, 193, 0.3);
    border-radius: 5px;
    padding: 20px;
    .locations {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #021011;
      p {
        margin-bottom: 0;
      }
    }
  }
}
.from_to {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  &__box {
    p {
      margin-bottom: 0;
    }
    &__title {
      font-size: 12px;
      line-height: 14px;
      color: #ff8413;
      margin-left: 34px;
    }
    &__date {
      margin-top: 5px;
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-column-gap: 10px;
      align-items: start;
      p {
        font-size: 16px;
        line-height: 19px;
        color: #021011;
        margin-bottom: 5px;
      }
    }
  }
}
.total_sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
}
</style>
